$(document).ready(function() {


    $('.carrier-form').change(function() {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });


    // update cart quantity
    $('.cart-update').submit(function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            success: function(data) {
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // remove cart size
    $('.btn-cart-remove').on('click', function(event) {
        event.preventDefault();
        var data = {};
        data[$(this).attr('data-name')] = 0;
        $.ajax({
            type: 'POST',
            url: $(this).attr('data-action'),
            data: data,
            success: function(data) {
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // remove cart item
    $('.btn-cart-remove-all').on('click', function(event) {
        event.preventDefault();
        var currentTable = $(this).attr('data-product-id');
        var inputs = $(currentTable).find('.i-number');
        var data = {};

        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: $(this).attr('data-action'),
            data: data,
            success: function(data) {
                window.location.href = '/current/cart';
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });


    // Coupon form
    $(".burn-coupon-form").submit(function(event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });


    // calculate each product total
    function singleProductQty() {
        $('.cart-product').each(function () {
            var inputs = $(this).find('.i-number');
            var total = 0;

            inputs.each(function () {
                total += parseInt($(this).val());
            });

            $(this).find('.single-product-qty').text(total);
        });
    }


    // calculate total quantity
    function totalQty() {
        var total = 0;

        $('.single-product-qty').each(function () {
            total += parseInt($(this).text());
        });

        $('#total-qty').text(total);
    }

    // calculate total quantity
    function totalDiscount() {
        var total = 0;

        $('.discount-value').each(function () {
            var currentValue = parseFloat($(this).text()).toFixed(2);
            total = parseFloat(total) + parseFloat(currentValue);
        });

        $('#total-discount').text(parseFloat(total).toFixed(2) + "€");
    }


    singleProductQty();
    totalQty();
    totalDiscount();

});