$(document).ready(function() {

    $('.js-open-submenu').on('click', function (e) {
        e.preventDefault();
        var submenuId = $(this).data('open');
        $('.js-mobile-menu').hide();
        $('.close-offcanvas-menu').hide();
        $('#' + submenuId).addClass('js-submenu-open');
        $('.js-mobile-menu-back').fadeIn();
    });
    $('.js-mobile-menu-back').on('click', function (e) {
        e.preventDefault();
        $('.js-submenu').removeClass('js-submenu-open');
        $('.js-mobile-menu-back').hide();
        $('.js-mobile-menu').fadeIn();
        $('.close-offcanvas-menu').fadeIn();
    });

    $('#mobile-menu-trigger').on('click', function () {
        $(this).children('img').toggleClass('hidden');
        $('.mobile-menu, .mobile-menu__search').fadeToggle();
        $('body').toggleClass('overflow-hidden');
    });

    $('.mobile-menu__language').on('click', function () {
        $(this).siblings('ul').slideToggle();
    });
});


//video ebt


jQuery(document).ready(function() {
    var video = jQuery('#ebt-video');
    jQuery('#play-button').on('click', function() {
        jQuery(this).hide();
        video.get(0).play();
        video.css("opacity", "1");
    });
});



