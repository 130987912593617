$(document).ready(function() {

    var url = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCevTmIBnlFMB5bJ6PTuHzhoF8LnmO87jw";
        $.getScript( url, function() {
            if (document.getElementById('gmaps_lat') != null) {
                if (document.getElementById('gmaps_lat').value != "45.476071") {
                    center = {lat : parseFloat(document.getElementById('gmaps_lat').value), lng: parseFloat(document.getElementById('gmaps_long').value)}
                    zoom_init = 10;
                } else {
                    center = {lat : 41.902782, lng: 12.496365}
                    zoom_init = 4;
                    if (Foundation.MediaQuery.is('small only')) {
                        zoom_init = 2;
                      }
                }    
            initMap(center, zoom_init);
            $('#directions').attr('href','https://www.google.com/maps/dir/?api=1&destination='+document.getElementById('gmaps_lat').value+','+document.getElementById('gmaps_long').value+'&travelmode=driving')
            }
        });

});

var markers_name = [];

var marker_list = [];

var zoom = 10;

var marker_current_position;

function geocode_address() {

    var geocoder = new google.maps.Geocoder();

    for (i=0;i<markers_name.length;i++) {
        document.getElementsByClassName(markers_name[i].title)[0].setAttribute("style","order:0");
    }

    var lat = '';
    var lng = '';
    var address;

    if (Foundation.MediaQuery.atLeast('medium')) {

        var address = document.getElementById('search_location').value;

    } else {

        var address = document.getElementById('search_location_mobile').value;

    }

    geocoder.geocode( { 'address': address}, function(results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
            zoom = 10;
            lat = results[0].geometry.location.lat();
            lng = results[0].geometry.location.lng();
            if (marker_current_position != undefined) {
            marker_current_position.setMap(null);
            }
            marker_current_position = new google.maps.Marker({title: "Current Position", position: {lat: lat, lng: lng}, map: map,icon: 'https://lopificio.it/skins/current-skin/images/icons/my-position.png'});
            //console.log(marker_list[findNearestMarker(lat,lng,marker_list)]);
            var nearest_marker = marker_list[findNearestMarker(lat,lng,marker_list)];
            map.panTo({lat: lat, lng: lng})
            document.getElementsByClassName(nearest_marker.title)[0].setAttribute("style","order:-1");
            //map.panTo(nearest_marker.getPosition());
            map.setZoom(zoom);

            //nearest_marker.infowindow.open(nearest_marker.get('map'), nearest_marker);
        } else {
            alert("Geocode was not successful for the following reason: " + status);}
    });

};

function initMap(center,zoom_init) {
    // Styles a map in night mode.
      //var shop = {lat: parseFloat(document.getElementById('gmaps_lat').value), lng: parseFloat(document.getElementById('gmaps_long').value)};
      map = new google.maps.Map(document.getElementById('map'), {
      center: center,
      disableDefaultUI: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      zoom: zoom_init
    });


    for (i=0; i<document.getElementsByClassName('store-detail').length; i++) {
        var store = {lat: parseFloat(document.getElementsByClassName('store-detail')[i].children[0].textContent), lng: parseFloat(document.getElementsByClassName('store-detail')[i].children[1].textContent)};

        markers_name[i] = document.getElementsByClassName('store-detail')[i].children[2].textContent;

        markers_name[i] = new google.maps.Marker({title: document.getElementsByClassName('store-detail')[i].children[2].textContent, position: store, map: map, icon: 'https://lopificio.it/skins/current-skin/images/icons/position.png'});

        marker_list[i] = markers_name[i];

        google.maps.event.addListener(marker_list[i], 'click', (function(marker, i) {
            return function() {
                map.setCenter(marker.getPosition());
                map.setZoom(12);
            }
          })(marker_list[i], i));

        /*marker_list[i].addListener('click', function() {
            map.setZoom(12);
            map.setCenter(marker_list[i].getPosition());
          });*/

    }


    var markerCluster = new MarkerClusterer(map, marker_list,
        {imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'});

    if (document.getElementById('gmaps_lat').value != "45.476071") {

        var store = {lat: parseFloat(document.getElementById('gmaps_lat').value), lng: parseFloat(document.getElementById('gmaps_long').value)};

        var marker_store = new google.maps.Marker({ position: store, map: map, icon: 'https://lopificio.it/skins/current-skin/images/icons/position.png'});

    } else {

        google.maps.event.addListener(map, 'idle', function() {
            showVisibleMarkers(map)
        });

    }

}

function rad(x) {return x*Math.PI/180;}

function findNearestMarker(lat,lng,marker_list){
        var R = 6371; // radius of earth in km
        var distances = [];
        var closest = -1;
        for( i=0; i<marker_list.length; i++ ) {
            var mlat = marker_list[i].position.lat();
            var mlng = marker_list[i].position.lng();
            var dLat  = rad(mlat - lat);
            var dLong = rad(mlng - lng);
            var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.cos(rad(lat)) * Math.cos(rad(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
            var d = R * c;
            distances[i] = d;
            if ( closest == -1 || d < distances[closest] ) {
                closest = i;
                }
        }
    return closest;
}

function showVisibleMarkers(map) {
    
    var bounds = map.getBounds(),
        count = 0;
                                   
    for (var i = 0; i < marker_list.length; i++) {
        var marker = marker_list[i];
                                           
        if(bounds.contains(marker.getPosition())===true) {
            document.getElementsByClassName(marker.title)[0].style.display = "block"
            count++;
        }
        else {
            document.getElementsByClassName(marker.title)[0].style.display = "none"
        }
    }

    while (countVisibleMarkers() == 0) {
        map.setZoom(map.getZoom() - 1);
    }

}

function countVisibleMarkers() {
    var bounds = map.getBounds(),
        count = 0;
                                   
    for (var i = 0; i < marker_list.length; i++) {
        var marker = marker_list[i];
                                           
        if(bounds.contains(marker.getPosition())===true) {
            count++;
        }
    }

    return count;
}

function showLocation(position) {
    current_latitude = position.coords.latitude;
    current_longitude = position.coords.longitude;

    if (Foundation.MediaQuery.atLeast('medium')) {

        document.getElementById('search_location').value = current_latitude + ',' + current_longitude;

    } else {

        document.getElementById('search_location_mobile').value = current_latitude + ',' + current_longitude;

    }

    geocode_address();

 }

function errorHandler(err) {
    if(err.code == 1) {
       alert("Error: Access is denied!");
    } else if( err.code == 2) {
       alert("Error: Position is unavailable!");
    }
}
    
function getLocation() {

    if(navigator.geolocation) {

       var options = {timeout:60000};
       navigator.geolocation.getCurrentPosition(showLocation, errorHandler, options);
       
    } else {
       alert("Sorry, browser does not support geolocation!");
    }
}