$(document).ready(function() {
    'use strict';


    // Qty table
    $('.i-number-btn').on('click', function () {
        var $this = $(this);
        var inputName = $this.siblings('.i-number').attr('name');
        var availability = $('#' + inputName);
        var availabilityValue = parseInt(availability.text());
        var max = $this.siblings('.i-number').attr('data-max');
        var min = $this.siblings('.i-number').attr('data-min');

        if ($this.hasClass('i-number-plus') && availabilityValue > min) {
            availability.text(availabilityValue - 1);
        }
        if ($this.hasClass('i-number-minus') && availabilityValue < max) {
            availability.text(availabilityValue + 1);
        }

        calculateTotal();
    });

    $('.i-number').change(function(){
        var $this = $(this);
        var inputName = $this.attr('name');
        var availability = $('#' + inputName);
        var availabilityValue = parseInt(availability.text());
        var max = $this.attr('data-max');
        
        if($(this).val().trim().length === 0){
            $(this).val(0);
            availability.text(max);
            calculateTotal();
        }

    });

    $('.i-number').on('change, mouseup, keyup', function () {
        var $this = $(this);
        var inputName = $this.attr('name');
        var availability = $('#' + inputName);
        var availabilityValue = parseInt(availability.text());
        var max = $this.attr('data-max');
        var min = $this.attr('data-min');

        if($(this).val().trim().length === 0){
            $(this).css('color', 'black');
        }

        if (parseInt($this.val()) >= min && parseInt($this.val()) <= max) {
            $(this).css('color', 'black');
            availability.text(max - parseInt($this.val()));
            calculateTotal();
        } else if ($this.val()>0 && max.trim().length!=0) {
            $(this).css('color', 'red');
            availability.text(max);
        }
        

    });

    $('.qty-max').on('click', function () {
        var $this = $(this);
        var inputName = $this.attr('data-input');
        var input = $('.i-number[name="' + inputName + '"]');
        var inputMax  = parseInt(input.attr('data-max'));
        var availability = $('#' + inputName);

        input.val(inputMax);
        availability.text(0);

        $('#add-to-cart').prop('disabled', false);
        calculateTotal();
    });

    $('#qty-max-all').on('click', function () {
        $('.qty-max').each(function () {
            $(this).trigger('click');
        });
    });

    calculateTotal();



    // Add to cart
    $('#catalog-container, #product-container').on('submit', '.addtocart-form', function(event) {
        event.preventDefault();
        var form = $(this);
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (!data.success) {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    }
                    else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    }
                    else if (data.code == 'RULE_REJECT' && data.rejectCartRuleName) {
                        showMessageBox('danger', (msg['MSG_RULE_REJECT'] || '').replace(/:rule/g, data.rejectCartRuleName))
                    }
                    else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                }
                else {
                    updateCartCount(form);
                    location.href = '/' + $('html').attr('lang') + '/cart';
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

});


function updateCartCount(form, callback) {
    $('.header-utilities__cart').parent().load(window.location + '?' + new Date().getTime() + ' .header-utilities__cart:lt(1)', function() {
        if (callback) {
            callback();
        }
    });
}


function calculateTotal() {

    // Calculate availability total
    var availabilityTotal = 0;

    $('.availability').each(function () {
        availabilityTotal += parseInt($(this).text());
    });

    $('#availability-total').text(availabilityTotal);


    // Calculate order total
    var orderTotal = 0;

    $('.i-number').each(function () {
        orderTotal += parseInt($(this).val());
    });

    $('#order-total').text(orderTotal);


    // Calculate price total
    var singlePrice = parseFloat($('#price').text()).toFixed(2);
    var priceTotal = parseFloat(orderTotal * singlePrice).toFixed(2);
    $('#price-total').text(priceTotal + "€");
}


function preventNotNumericValues(e) {
    var charCode = (e.which) ? e.which : e.keyCode;

    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(charCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (charCode === 65 && ( e.ctrlKey === true || e.metaKey === true ) ) ||
        (charCode >= 35 && charCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
        e.preventDefault();
    }
}

function InputNumber(option) {
    return this.each(function() {
        var $this = $(this);

        $this.keydown(preventNotNumericValues);
        $this.keyup(function() {
            option.onChange($this.get(0));
        });

        $this.max = $this.attr('data-max');
        $this.min = $this.attr('data-min');

        $('<a class="i-number-btn i-number-minus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/profile/qty-minus.svg"/></a>')
            .insertBefore($this)
            .on('click', function(event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var min = $this.min || 0;
                if ($.isNumeric(val) && val > min) {
                    $this.val(val - 1);
                } else {
                    $this.val(0);
                }
                option.onChange($this.get(0));
            });
        $('<a class="i-number-btn i-number-plus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/profile/qty-plus.svg"/></a>')
            .insertAfter($this)
            .on('click', function(event) {
                event.preventDefault();
                var val = parseInt($this.val());
                var max = $this.max || Number.MAX_VALUE;
                if (!$.isNumeric(val)) {
                    $this.val(0);
                } else if (val >= $this.min && val < max) {
                    $this.val(val + 1);
                }
                option.onChange($this.get(0));
            });
    });
}

$.fn.inputNumber = InputNumber;

function loadQtyInputs() {
    $('.i-number').inputNumber({
        onChange: function(object) {
            $(object).closest('form').find('input[type="submit"]').prop('disabled', false);
        }
    });
}