$( document ).ready(function() {

	if ($('#search-type').html() == undefined) {
		$('.av-search').addClass('hide');
		$('.search-li').addClass('hide');
	} else {
		$('.av-search').removeClass('hide');
		$('.search-li').removeClass('hide');
	}

	if (window.location.search != "") {

		var i = 0;

		if (document.getElementById('total-post') != undefined) {
		
			while (i < (document.getElementById('total-post').value)) {

				if (document.getElementsByClassName('blog-post-text')[i] != undefined) {

				var oldString = window.location.search.split("=")[1],
				newString = '<span style="color:red">'+window.location.search.split("=")[1]+'</span>',
				newText = String(document.getElementsByClassName('blog-post-text')[i].innerHTML).replace(new RegExp(oldString, "g"),newString);

				document.getElementsByClassName('blog-post-text')[i].innerHTML = newText;

				}

				i++;

			}

		}

	}

	if (document.getElementById('search-type') != null) {

		document.getElementById('search-archive').oninput = function() {
			document.getElementsByClassName('av-search-button')[0].setAttribute('href',document.getElementById('search-type').value+"?search="+document.getElementById('search-archive').value)
		};

		$("#search-archive").on('keyup', function (e) {
			if (e.keyCode == 13) {
				document.getElementsByClassName('av-search-button')[0].click();
			}
		});

	}

	function openSearch(){
		$('.av-search-wrapper')
		.addClass('av-show-search')
		.delay(100)
		.queue(function(next) {
			$(this).addClass('av-opened-search');
			next();

		})
		/**DETERMINO QUANTO HO SCROLLATO E PORTO LA PAGINA IN QUEL PUNTO ALL'APERTURA DEL MENU*/
		/*var scrollHeight = document.body.scrollTop || document.documentElement.scrollTop;
		$('body').css('top', -(scrollHeight) + 'px')
         .addClass('av-noscroll-page');*/
	};

	function closeSearch(){
		/*var pagePosition = $('body').css('top');
			pagePosition = pagePosition.replace('px', '');
			pagePosition = pagePosition.replace('-','');*/

		$('.av-search-wrapper')
		.removeClass('av-opened-search')
		.delay(500)
		.queue(function(next) {
			$(this).removeClass('av-show-search');
			next();
		})
		/*.queue(function(next) {
	        $('body').removeClass('av-noscroll-page');
	        $(document).scrollTop( pagePosition );
	        $('body').removeAttr('style');
			next();
		})*/
	};
	
	
	$('.av-search:not(.av-close-search)').click(function(event) {
		openSearch();
		event.preventDefault();

	});
	
	$('.av-close-search').click(function(event) {
		closeSearch();
		event.preventDefault();

	});


	/***FUNZIONE PER CALCOLO INSERIMENTO CARATTERI NELL'inputm, quando arriva a 3 mostro i suggerimenti ricerca e nascondo i link utili**/
	/*function calcolaCaratteri(){
		var lunghezza = $('.search-form input').val().length;
		if (lunghezza == 3){
			$('.av-highlights-wrapper').slideUp();
			$('.av-suggestion-wrapper').fadeIn();
		}
		if (lunghezza <= 2){
			$('.av-suggestion-wrapper').fadeOut();
		}
	}

	$( ".search-form input" ).keyup(function() {
  		calcolaCaratteri();
	});*/





});