'use strict';

var dropCookie = true;
var cookieDuration = 365;
var cookieName = 'complianceCookie';
var cookieValue = 'on';

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function exists(selector) {
    try {
        return $(selector).length > 0;
    } catch {
        return false;
    }
}

function scrollToElement(selector) {
    if (exists(selector)) {
        const offset = $(selector).offset().top;
        const headerOffset = $('.sticky-section').toArray().reduce((total, el) => {
            if (el.offsetTop < offset || window.getComputedStyle(el).position == 'sticky') {
                return total + el.offsetHeight;
            }
            else {
                return total;
            }
        }, 0);
        return new Promise((resolve) => {
            $("html").animate({ scrollTop: offset - headerOffset }, resolve);
        });
    }
    else {
        return Promise.reject();
    }
}

function getUrlParam(variable) {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
            return pair[1];
        }
    }
}

var prefixes = {
    3: 93,
    6: 355,
    61: 213,
    12: 684,
    1: 376,
    9: 244,
    5: 1264,
    10: 672,
    4: 1268,
    11: 54,
    7: 374,
    15: 297,
    14: 61,
    13: 43,
    16: 994,
    30: 1242,
    23: 973,
    19: 880,
    18: 1246,
    35: 375,
    20: 32,
    36: 501,
    25: 229,
    26: 1441,
    31: 975,
    28: 591,
    17: 387,
    34: 267,
    29: 55,
    104: 246,
    231: 1284,
    27: 673,
    22: 359,
    21: 226,
    24: 257,
    114: 855,
    45: 237,
    37: 1,
    51: 238,
    121: 1345,
    39: 236,
    207: 235,
    44: 56,
    46: 86,
    52: 61,
    38: 891,
    47: 57,
    116: 269,
    40: 242,
    43: 682,
    48: 506,
    42: 225,
    97: 385,
    50: 53,
    53: 357,
    54: 420,
    58: 45,
    57: 253,
    59: 1767,
    60: 1809,
    216: 670,
    62: 593,
    64: 20,
    203: 503,
    87: 240,
    66: 291,
    63: 372,
    68: 251,
    71: 500,
    73: 298,
    70: 679,
    69: 358,
    74: 33,
    80: 594,
    170: 689,
    76: 241,
    84: 220,
    79: 995,
    56: 49,
    81: 233,
    82: 350,
    88: 30,
    83: 299,
    78: 1473,
    86: 590,
    91: 1671,
    90: 502,
    85: 224,
    92: 245,
    93: 592,
    98: 509,
    96: 504,
    94: 852,
    99: 36,
    107: 354,
    103: 91,
    100: 62,
    105: 964,
    101: 353,
    106: 98,
    102: 972,
    108: 39,
    109: 1876,
    111: 81,
    248: 44,
    110: 962,
    122: 996,
    112: 254,
    115: 686,
    118: 850,
    119: 82,
    120: 965,
    113: 996,
    123: 856,
    132: 371,
    124: 961,
    129: 266,
    128: 231,
    133: 218,
    126: 423,
    130: 370,
    131: 352,
    142: 853,
    246: 389,
    137: 261,
    150: 265,
    152: 60,
    149: 960,
    139: 223,
    147: 356,
    138: 692,
    144: 596,
    145: 222,
    148: 230,
    239: 262,
    151: 52,
    136: 373,
    135: 377,
    140: 976,
    146: 1664,
    249: 382,
    134: 212,
    153: 258,
    141: 95,
    154: 264,
    163: 674,
    162: 977,
    160: 31,
    8: 599,
    155: 687,
    166: 64,
    159: 505,
    156: 227,
    158: 234,
    165: 683,
    157: 672,
    143: 1670,
    161: 47,
    167: 968,
    173: 92,
    179: 680,
    168: 507,
    171: 675,
    180: 595,
    169: 51,
    172: 63,
    176: 64,
    174: 48,
    178: 351,
    177: 1787,
    181: 974,
    182: 262,
    183: 40,
    184: 7,
    185: 250,
    125: 1758,
    236: 685,
    197: 378,
    201: 239,
    186: 966,
    198: 221,
    250: 381,
    188: 248,
    196: 232,
    191: 65,
    195: 421,
    193: 386,
    187: 677,
    199: 252,
    241: 27,
    89: 500,
    67: 34,
    247: 34,
    127: 94,
    192: 290,
    117: 1869,
    175: 508,
    229: 1784,
    251: 970,
    189: 249,
    200: 597,
    205: 268,
    190: 46,
    41: 41,
    204: 963,
    220: 886,
    211: 737,
    221: 255,
    210: 66,
    209: 228,
    212: 690,
    215: 676,
    218: 1868,
    214: 216,
    217: 90,
    213: 993,
    206: 1649,
    219: 688,
    223: 256,
    222: 380,
    2: 471,
    77: 44,
    225: 1,
    232: 1340,
    226: 598,
    227: 998,
    234: 678,
    228: 39,
    230: 58,
    233: 84,
    235: 681,
    65: 212,
    238: 967,
    243: 243,
    242: 260,
    244: 263
};

function setTelPrefix($dest, $source) {
    var country_id = $source.val();
    var prefix = typeof prefixes[country_id] != 'undefined' ? '+' + prefixes[country_id] : '';
    $dest.val(prefix);
}

function fillCountryPrefix(options) {
    return this.each(function () {
        var $this = $(this);
        var $source = $($this.attr('data-source'));
        $this.hasBeenManuallyEntered = false;

        if ($this.val().length === 0) {
            setTelPrefix($this, $source);
        } else {
            $this.hasBeenManuallyEntered = true;
        }

        $($source).change(function () {
            if (!$this.hasBeenManuallyEntered) {
                setTelPrefix($this, $source);
            }
        });

        $this.on('input', function () {
            var val = $this.val().trim();
            if (val.length > 0 && (val.indexOf('+') === 0 || val.indexOf('0') === 0)) {
                $this.hasBeenManuallyEntered = true;
            } else {
                $this.hasBeenManuallyEntered = false;
            }
        });
    });
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}

$.fn.fillCountryPrefix = fillCountryPrefix;

function getHeaderHeight() {
    document.body.style.setProperty('--header-height', $('header').height() + 'px');
}

$(document).ready(function () {
    $('a[href^="#"]').on("click", function (event) {
        event.preventDefault();

        const hash = $(this).attr("href");
        scrollToElement(hash)
            .then(() => (window.history.pushState(null, '', window.location.pathname + hash)))
            .catch(() => { });
    });

    if (window.location.hash.includes("-anchor")) {
        scrollToElement(window.location.hash.replace("-anchor", ""))
            .then(() => { })
            .catch(() => { });
    }


    if (getUrlParam('errorCode') == '263') {
        $('#login-form').addClass('hide');
        $('#login-form-otp').removeClass('hide');
    }

    getHeaderHeight();
    $(window).on('resize orientationchange', getHeaderHeight);

    $('.boutique-menu li > a').on('click', function () {
        $('.boutique-menu li > a').removeClass('active');
        this.classList.add('active');
    });
});