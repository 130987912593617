$(document).ready(function() {
    'use strict';

    initIntlTelInput();

    function submitUserUpdateForm(form) {
        const $form = $(form)
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#password-change-form').submit(function(event) {
        event.preventDefault();
        if ($('#password1').val.length>8) {
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                beforeSend: function(data) {
                    $(this).find(':input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        showMessageBox('success', data.data);
                    } else {
                        showMessageBox('danger', data.data);
                    }
                },
                complete: function(data) {
                    $(this).find(':input').prop('disabled', false);
                }
            });
        } else {
            showMessageBox('danger', msg['ERR_SHORTPASSWORD']);
        }
    });
    $('#1to2step').click(function(event) {
        var form = $('#create-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                $('#idnewotp').val(data.id);
                $('#qrcode').attr('src','data:image/png;base64, ' + data.data);
                $('#firstStep').hide();
                $('#secondStep').show();
                console.log(data);
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#enableOTP').click(function(event) {
        var form = $('#enable-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#deleteOTP').click(function(event) {
        var form = $('#delete-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
	});
});

function initIntlTelInput() {
    const cel = $('[id*="cel"]').not('[type="hidden"]');
    const cel_prefix = $('[id*="cel"][type="hidden"]');

    if (cel.length > 0 && cel_prefix.length > 0) {
        cel.val(cel_prefix.val() + cel.val());
        const iti = window.intlTelInput(cel[0], {
            separateDialCode: true,
            utilsScript: '/skins/current-skin/js/vendor/utils.js',
        });
    
        cel.on('countrychange', function () {
            const prefix = '+' + iti.getSelectedCountryData().dialCode;
            $('[id*="cel_prefix"]').val(prefix);
        });
    }
}