$(document).ready(function() {

    $('.datepicker').pickadate()
    $('.timepicker').pickatime()

    var number = 0;

    $('#sortpicture1').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-1').addClass('hide');
                    $('#image-uploaded-1').removeClass('hide');
                    $('#link-image1').val(JSON.parse(php_script_response).link);
                    number++;
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture2').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-2').addClass('hide');
                    $('#image-uploaded-2').removeClass('hide');
                    $('#link-image2').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture3').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-3').addClass('hide');
                    $('#image-uploaded-3').removeClass('hide');
                    $('#link-image3').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture4').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-4').addClass('hide');
                    $('#image-uploaded-4').removeClass('hide');
                    $('#link-image4').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#sortpicture5').change(function() {
        var file_data = $('#sortpicture1').prop('files')[0];   
        var form_data = new FormData();                  
        form_data.append('file', file_data);
        form_data.append('type', 'opi');
        $.ajax({
            url: 'https://ftp.dev.zero11.net/storage/opificio/up.php', // point to server-side PHP script 
            dataType: 'text',  // what to expect back from the PHP script, if anything
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,                         
            type: 'post',
            success: function(php_script_response){
                if (JSON.parse(php_script_response).status == 'OK') {
                    $('#upload-div-5').addClass('hide');
                    $('#image-uploaded-5').removeClass('hide');
                    $('#link-image5').val(JSON.parse(php_script_response).link);
                    number++
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
         });
        
    });

    $('#show-private-contact').on('click', function () {

        $('.form_designer').attr('style','opacity:1;transition:1s;')
        $('#business-contact').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-send').addClass('hide');
        $('#private-contact').removeClass('hide');
        $('#private-contact-personal').removeClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-send').addClass('hide');

    });

    $('.close-contact-form').on('click', function () {
        $('.form_designer').attr('style','opacity:0;transition:1s;')
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').addClass('hide');
    });

    $('#to-budget-private').on('click', function () {

        to_budget_private();

    });

    $('#back-personal-private').on('click', function () {

        to_personal_private();
        
    });

    $('#back-budget-private').on('click', function () {

        to_budget_private();
        
    });

    $('#to-description-private').on('click', function () {

        to_description_private();

    });

    $('#to-send-private').on('click', function () {

        to_send_private(number);
        
    });

    $('.private-personal').on('click', function () {

        to_personal_private();
        
    });

    $('.private-description').on('click', function () {

        to_description_private();
        
    });

    $('.private-budget').on('click', function () {

        to_budget_private();
        
    });

    $('.private-send').on('click', function () {

        to_send_private(number);
        
    });

    $('#show-business-contact').on('click', function () {

        $('.form_designer').attr('style','opacity:1;transition:1s;')
        $('#business-contact').removeClass('hide');
        $('#business-contact-personal').removeClass('hide');
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-send').addClass('hide');
        $('#private-contact').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-budget').addClass('hide');
        $('#private-contact-send').addClass('hide');

    });

    $('.close-contact-form').on('click', function () {
        $('.form_designer').attr('style','opacity:0;transition:1s;')
        $('#business-contact-budget').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').addClass('hide');
    });

    $('#to-budget-business').on('click', function () {

        to_budget_business();

    });

    $('#back-personal-business').on('click', function () {

        to_personal_business();
        
    });

    $('#to-send-business').on('click', function () {

        to_send_business();
        
    });

    $('.business-personal').on('click', function () {

        to_personal_business();
        
    });

    $('.business-budget').on('click', function () {

        to_budget_business();
        
    });

    $('.business-send').on('click', function () {

        to_send_business();
        
    });

    const url = new URL(window.location.href);
    if (url.searchParams.has('open')) {
        const modal = $(url.searchParams.get('open'));
        if (modal.length > 0) {
            modal.foundation('open');
        }
    }
});

function sendContact() {
    var $form = $('#contact-form-exhibition');

    if (document.getElementById('email').value != "") {

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            contentType: 'application/x-www-form-urlencoded',
            beforeSend: function(data) {
    
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_MESSAGE_SENT']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                grecaptcha.reset();
            }
        });

    } else {
        showMessageBox('danger', msg['ERR_FILLEMAILFIELD']);
        grecaptcha.reset();
    }

}

function to_personal_private() {

    $('#private-contact-budget').addClass('hide');
    $('#private-contact-personal').removeClass('hide');
    $('#private-contact-send').addClass('hide');
    $('#private-contact-space-description').addClass('hide');

}

function to_budget_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').removeClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').addClass('hide');

    }

}

function to_description_private() {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-send').addClass('hide');
        $('#private-contact-space-description').removeClass('hide');

    }

}

function to_send_private(number) {

    $("#private-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#private-contact").valid() == true) {

        $('#private-contact-budget').addClass('hide');
        $('#private-contact-personal').addClass('hide');
        $('#private-contact-space-description').addClass('hide');
        $('#private-contact-send').removeClass('hide');

        document.getElementById('private-contact-name-lastname').innerHTML = $('#private-name').val();
        document.getElementById('private-contact-email').innerHTML = $('#private-email').val();
        document.getElementById('private-contact-phone').innerHTML = $('#private-phone').val();
        document.getElementById('private-contact-city').innerHTML = $('#private-city').val();

        document.getElementById('private-contact-description').innerHTML = findCheckedInput('rinnova');

        document.getElementById('private-contact-colors').innerHTML = $('#private-space-colors').val();
        document.getElementById('private-contact-budget-input').innerHTML = findCheckedInput('budget');

        if (number != 0) {

            $('#number-count').html($('#number-count').html() + number);

        } else {

            $('#private-image-div').addClass('hide');

        }

    }

}

function findCheckedInput(name) {

    var result = '';
    var x = document.getElementsByName(name);
    var i;
        for (i = 0; i < x.length; i++) {
            if (x[i].checked == true) {
                result = result.concat(x[i].value) + ' '    
            }
        }
    return result;
}

function contactRecaptchaCallbackPrivate() {

    if ($("#privacy-private").is(":checked") == true) {

    $.ajax({
            type: $('#private-contact').attr('method'),
            url: $('#private-contact').attr('action'),
            data: $('#private-contact').serialize(),
            dataType: 'json',
            beforeSend: function(data) {
            },
            success: function(data) {
              if (data.success) {
                showMessageBox('success', msg['MSG_MESSAGE_SENT']);
              } else {
                showMessageBox('danger', data.errors.message);
              }
              $('.form_designer').attr('style','opacity:0;transition:1s;')
              $('#private-contact-budget').addClass('hide');
              $('#private-contact-personal').addClass('hide');
              $('#private-contact-send').addClass('hide');
            },
            failed: function(data) {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
              grecaptcha.reset();
            },
            complete: function(data) {
                
            }
    })

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }
}

function to_budget_business() {

    $("#business-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#business-contact").valid() == true) {

        $('#business-contact-budget').removeClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').addClass('hide');

    }

}

function to_send_business() {

    $("#business-contact").validate({
        errorPlacement: function(error, element) {
        error.appendTo("");
        }
    });

    if ($("#business-contact").valid() == true) {

        $('#business-contact-budget').addClass('hide');
        $('#business-contact-personal').addClass('hide');
        $('#business-contact-send').removeClass('hide');

        document.getElementById('business-contact-name-lastname').innerHTML = $('#business-name').val();
        document.getElementById('business-contact-email').innerHTML = $('#business-email').val();
        document.getElementById('business-contact-phone').innerHTML = $('#business-phone').val();
        document.getElementById('business-contact-description').innerHTML = findCheckedInput('rinnova-business');

        document.getElementById('business-contact-colors').innerHTML = $('#business-space-colors').val();
        document.getElementById('business-contact-budget-input').innerHTML = findCheckedInput('business-budget');

    }

}

function contactRecaptchaCallbackBusiness() {

    $.ajax({
            type: $('#business-contact').attr('method'),
            url: $('#business-contact').attr('action'),
            data: $('#business-contact').serialize(),
            dataType: 'json',
            beforeSend: function(data) {
            },
            success: function(data) {
              if (data.success) {
                showMessageBox('success', msg['MSG_MESSAGE_SENT']);
              } else {
                showMessageBox('danger', data.errors.message);
              }
              $('.form_designer').attr('style','opacity:0;transition:1s;')
              $('#business-contact-budget').addClass('hide');
              $('#business-contact-personal').addClass('hide');
              $('#business-contact-send').addClass('hide');
              $('#business-contact').foundation('close');
            },
            failed: function(data) {
              showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
              grecaptcha.reset();
            },
            complete: function(data) {
                
            }
    })
}

function contactFormRecaptcha() {

    $('#contact-form').validate({
        errorPlacement: function(error, element) {
            error.appendTo("");
          }
    });

    if (document.getElementById('privacy').checked) {
        $('#contact-form-send input[type=submit]').prop('disabled', false);
        sendContact();
    }
    

    if ($("#privacy").is(":checked") == true) {

    if ($('#contact-form').valid()) {

    $.ajax({
        type: $('#contact-form').attr('method'),
        url: $('#contact-form').attr('action'),
        data: $('#contact-form').serialize(),
        dataType: 'json',
        beforeSend: function(data) {
        },
        success: function(data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            if ($('#report_conversion').val() == '1')
                gtag_report_conversion ();
            if (document.getElementById('contact-boutique') != null) {
                $('#contact-boutique').foundation('close');
            }
            if (document.getElementById('subject') != undefined) {
                setTimeout(function() {
                    window.location.href = '/current/press-room'
                }, 500);
            }
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function(data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
          grecaptcha.reset();
        },
        complete: function(data) {
            
        }
    })
 
    } else {
        grecaptcha.reset();
    }

    } else {
        showMessageBox("danger", msg['ERR_PRIVACY'])
        grecaptcha.reset();
    }

}
