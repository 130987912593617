$(document).ready(function () {

    if (window.location.search != '') {
        if (document.getElementById('shoptype') != undefined) {
        var type = GetUrlValue('business');
        document.getElementById('shoptype').value = 'type:'+type;
        }
    }

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });


    // password recovery form
    $('#password-recover-form').submit(function(event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });

    });


    // subscribe form
    $('#subscribe-form').submit(function(event) {
        event.preventDefault();
        var $form = $(this);

        $('#subscribe-form').validate({
            errorPlacement: function(error, element) {
             }
          });

        if ($('#subscribe-form').valid()) {

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    window.location = $form.data('redirectsuccess');
                } else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });

        }

    });

});

function GetUrlValue(VarSearch){
    var SearchString = window.location.search.substring(1);
    var VariableArray = SearchString.split('&');
    for(var i = 0; i < VariableArray.length; i++){
        var KeyValuePair = VariableArray[i].split('=');
        if(KeyValuePair[0] == VarSearch){
            return KeyValuePair[1];
        }
    }
}
